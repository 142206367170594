<script>
  import {
    required,
    helpers
  } from "@vuelidate/validators";

  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import userSearchModelWS  from "@/components/widgets/worksites/userSearchModelWS";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'pubblicazioneRapporto',
    props: [
        'projectID',
        'worksiteID',
        'taskID',
        'interventionID',
        'data',
    ],
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Gestione Mezzi cantiere",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          name:"",
          details:"",
          ownership:false,
          ownershipDesc:'',
          manufacturer:'',
          manufacturerDesc:'',
          kmStart:'',
          createdBy:this.$store.state.auth.currentUser,
        },
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:new Date().getFullYear().toString() + '-12-31',
        resultsList:[],
        teamLeaderID:'',
        qSearch:'',
        dataEdit:this.data,
        
      };
    },
    components: {
   
    },
    validations: {
      item: {
        name: {
          required: helpers.withMessage("Nome attrezzatura obbligatorio", required),
        },
        code: {
          required: helpers.withMessage("Codice identificativo attrezzatura obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca attrezzatura obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà attrezzatura obbligatoria", required),
        },
      },
      dataEdit: {
        name: {
          required: helpers.withMessage("Nome attrezzatura obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca attrezzatura obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà del attrezzatura obbligatoria", required),
        },
        number: {
          required: helpers.withMessage("Indicazione numero di pezzi utilizzati obbligatorio", required),
        },


      },

    },
    methods: {
      convertDatetime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      sendIt(){
        let self = this
        this.dataEdit.invioDocumento = true
        this.dataEdit.datiInvio = {
            createdBy: this.$store.state.auth.currentUser,
            date:moment().format('DD/MM/YYYY hh:mm:ss'),
            unixDate: moment.unix()
        }
        Swal.fire(
          {
            title: this.$t("t-sendit-confirm-setting-title"),
            text: this.$t("t-sendit-confirm-setting-text"),
            icon: this.$t("t-sendit-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-sendit-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-sendit-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-sendit-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-sendit-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
                self.updatedata()
            }
        });
      },
     
      splitStr(str,car){
            if (str){
            if(str.includes(car)==true) {
                let val =  str.split(car)
                return `${val[0]} ${val[1]}`
            } else{
                return str
            }
            } else {
            return ''
            }
        },
        updatedata(){
            console.log(this.$route.params)
          if (this.$route.params.id) {
            let  data = this.dataEdit
            delete data._id
            axios.put(`${this.UrlServer}intervention-reports/${this.$route.params.id}`, data, {
                headers: { authorization:this.tk},
                onUploadProgress: (event) =>
                {
                const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                let timerInterval;
                Swal.fire({
                    title: "Caricamento...",
                    timer: percentUploaded,
                    timerProgressBar: true,
                    didOpen: () => {
                    Swal.showLoading();
                    const timer = Swal.getPopup().querySelector("b");
                    timerInterval = setInterval(() => {
                        timer.textContent = `${Swal.getTimerLeft()}`;
                    }, 100);
                    },
                    willClose: () => {
                    clearInterval(timerInterval);
                    }
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('Caricamento...')
                    }
                });

                },
                } )
                .then((response)=>{
                if (response.data) {
                   this.$emit('updateTeamParent')
                }
            })
          }

        

        },
    },
    mounted() {
      if (this.data) {
        if(!this.data.teamLeaderID){
          this.teamLeaderID = ''
        } else {
          this.teamLeaderID = this.data.teamLeaderID
        
        }
        this.dataEdit = this.data
      }
      
    }
  };
</script>
<template>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="mb-3" v-if="data.worksite">
        <label for="choices-lead-input" class="form-label">Stato lavorazione</label>
        <select class="form-select" v-model="dataEdit.status" @change="updatedata">
          <option value="init">In attesa di presa in carico</option>
          <option value="processing">In lavorazione</option>
          <option value="pending">In attesa </option>
          <option value="problem">Problematica </option>
          <option value="completed">Completato </option>

        </select>
      </div>
    </div> 
    <div class="col-lg-6 col-md-12">
        <label for="send" class="form-label">Stampa rapporto</label><br>
        <button type="button" class="btn btn-danger">
            <span class="bx bx-printer"></span>
           Stampa
        </button>
    </div>
    </div>
    <div class="row">
    <div class="col-lg-6 col-md-12 mt-20 mb-20">

    </div> 
    </div>

  <div class="row">
    <div class="col-lg-12 col-md-12  mb-25">
        <label for="send" class="form-label">Invia rapporto</label><br>
        <template v-if="!dataEdit.invioDocumento">
            <template v-if="dataEdit.status=='completed'">
                <button type="button" class="btn btn-primary" @click="sendIt">
                <span class="bx bx-send"></span>
                    Invia al coordinatore
                </button>
            </template>
            <template v-else>
                <b-alert show variant="primary" class="alert-dismissible alert-label-icon label-arrow fade show" role="alert">
                    <i class="ri-user-smile-line label-icon"></i>Rapporto non ancora <strong>completato</strong>.  Il rapporto d'intervento risulta <strong>effettuato</strong> solo con l'indicazione di stato come <strong>Completato</strong> e l'<strong>invio</strong> del documento al coordinatore
                    
                </b-alert>
            </template>
        </template>
        <template v-else>
            <b-alert show variant="success" class="alert-dismissible alert-label-icon label-arrow fade show" role="alert">
            <i class="ri-notification-off-line label-icon"></i><strong>Rapporto inviato</strong>
          
            </b-alert>
        </template>

    </div>
  </div>

</template>