<script>
  import {
    required,
    helpers
  } from "@vuelidate/validators";

  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import userSearchModelWS  from "@/components/widgets/worksites/userSearchModelWS";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'gestioneProdottiCantiere',
    props: [
      'type',
      'projectID',
      'worksiteID',
      'taskID',
      'interventionID',
      'data',
    ],
    setup() {
      moment.locale('it')
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Gestione Mezzi cantiere",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          name:"",
          details:"",
          ownership:false,
          ownershipDesc:'',
          manufacturer:'',
          manufacturerDesc:'',
          kmStart:'',
          createdBy:this.$store.state.auth.currentUser,
        },
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:new Date().getFullYear().toString() + '-12-31',
        resultsList:[],
        teamLeaderID:'',
        qSearch:'',
        dataEdit:{},
        
      };
    },
    components: {
   
    },
    validations: {
      item: {
        name: {
          required: helpers.withMessage("Nome prodotto obbligatorio", required),
        },
        code: {
          required: helpers.withMessage("Codice identificativo prodotto obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca prodotto obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà prodotto obbligatoria", required),
        },
      },
      dataEdit: {
        name: {
          required: helpers.withMessage("Nome prodotto obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca prodotto obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà del prodotto obbligatoria", required),
        },
        number: {
          required: helpers.withMessage("Indicazione numero di pezzi utilizzati obbligatorio", required),
        },


      },

    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      
    },

    methods: {
      convertDatetime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      async fullSearch(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}intervention-reports/search/products/fulltext`,
            { 
            params: { 
                q: this.qSearch,
            },
            headers: { 'authorization':this.tk} 
            }
        )
        .then((response) => {
            if (response.data)  {
            this.resultsList = response.data
            }
        })
      },
      getList(){
        let urlRef= ''
        if (this.type=='worksite-task' && this.taskID!='') {
          urlRef = `items-by-interventionid/${this.taskID}/worksite-task`
        } else if(this.type=='intervention' && this.interventionID!='') {
          urlRef = `items-by-interventionid/${this.interventionID}/intervention`
        } else if(this.type=='project' && this.projectID!='') {
          urlRef = `items-by-interventionid/${this.interventionID}/project`
        }
         else {
          urlRef = `items-by-interventionid/${this.worksiteID}/worksite`
        }
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}intervention-reports/${urlRef}`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseList = response.data.results.data;
          this.n_results = response.data.n_results;
          this.paginated = response.data.results
          })
      },
      editdata(data) {
        this.dataEdit = data
      },
      initItem(){
        this.submitted = true;
        this.v$.$touch()
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}equipments/register`, this.item, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            if (response.data) {
              Swal.fire({  
                title: this.$t("t-msg-vehicle-init") , 
                text: this.$t("t-msg-vehicle-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              }).then(()=>{
                  document.getElementById("closemodalEquip").click();
              });
            }
        })
      },
      addElement(params){
        params.projectID = this.projectID
        params.worksiteID = this.worksiteID
        params.taskID = this.taskID
        params.interventionID = this.interventionID
        params.productID = params._id
        params.kmStart = 0.00
        params.kmEnd = 0.00
        params.cost = 0.00
        if (params._id){
          delete params._id
        }

        this.axiosInterceptor()
        axios.post(`${this.UrlServer}intervention-reports/add-product`, params, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            if (response.data) {
              this.getList()
              this.resultsList = []
              this.search=''
              Swal.fire({  
                title: this.$t("t-msg-element") , 
                text: this.$t("t-msg-element-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              })
            }
        })
      },
      deletedata(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}intervention-reports/worksite-item/${id}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getList() 
              })
            }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(responseList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return responseList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
            if (str){
            if(str.includes(car)==true) {
                let val =  str.split(car)
                return `${val[0]} ${val[1]}`
            } else{
                return str
            }
            } else {
            return ''
            }
        },
        selectItem(params){
          let array = this.responseList.filter(function(el){
            return el._id == params._id
          })
          if (array.length==0){
            this.addElement(params)
          } else {
            Swal.fire({
              title:"Prodotto già presente",
              text:"Prodotto che hai selezionato è già presente!",
              icon:"info",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }) 
          }
        },
        updatedata(){
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}intervention-reports/worksite-item/${this.dataEdit._id}`, this.dataEdit, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
            } )
            .then((response)=>{
              if (response.data) {
                document.getElementById("editclosemodalEquip").click();
              }
          })
        },
    },
    mounted() {
      if (this.data) {
        if(!this.data.teamLeaderID){
          this.teamLeaderID = ''
        } else {
          this.teamLeaderID = this.data.teamLeaderID
        }
      }
      this.getList()
    }
  };
</script>

<template>
  <div class="back-grey mt-20">
    <div class="card">
        <div class="card-header">
            <h5 class="card-title mb-0">Gestione prodotti e materiale utilizzato</h5>
        </div>
        <div class="card-body">
    <div class="row ">
        <div class="col-lg-12">         
            <div class="card-body pl-10 mb-10">
                <template v-if="addTabPlan==false">
                    <button class="btn btn-warning bg-gradient" type="button" @click="addTabPlan=true">
                        <i class="bx bx-search"></i>
                        <i class="bx bx-car"></i>
                       Ricerca Prodotto
                    </button>  
                </template>
                <template v-else>
                    <button class="btn btn-light" @click="addTabPlan=false">
                        X
                        Chiudi ricerca Prodotto
                    </button> 
                </template>
                <div style="float: right;">
                    <!--
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdropEquip">
                        Crea anagrafica Prodotto
                    </button>
                    -->
                </div>
            </div>
        </div>
    </div>
    <div v-if="addTabPlan">
        <div class="row background-btn-grey-1  mb-20 mt-20 pl-10" >
            <div class="col-md-12 col-lg-12">
               
                <div class="mb-3 form-icon">
                    
                    <input
                        type="text"
                        id="search"
                        v-model="qSearch"
                        placeholder="Digita qui termine di ricerca del prodotto..."
                        class="form-control-search p-3 mt-20 mb-0.5 w-full border border-blue-300 rounded"
                        @input="fullSearch()"
                    >

                    <ul
                      v-if=" len(resultsList) > 0"
                      class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
                    >
                      <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
                                 {{ len(resultsList) }} 
                                <template v-if="len(resultsList)>1">
                                  risultati
                                </template>
                                <template v-else>
                                  risultato
                                </template>  
                      </li>
                      <li
                        v-for="item in resultsList"
                        :key="item._id"
                        @click="selectItem(item)"
                        class="cursor-pointer hover:bg-gray-100 p-1"
                      >
                      <strong>{{ item.Descrizione }}</strong> <br>Cod. Art.: {{ item.CodArt }}  <br> Marca: {{ item.CodMarca }} 
                      </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
    <div class="card-header  mb-50">
              <div class="row align-items-center mb-25">
               
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Tutti
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">

                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
          <div class="card-body">
            <div class="table-card mb-1">
                <div class="row thRow  ">

                    <div class="col text-left">
                        Descrizione
                    </div>
                    <div class="col text-left">
                        Codice
                    </div>
                    <div class="col text-left">
                        N.
                    </div>
                    <div class="col text-left">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of responseList" :key="index">
                    <div class="col text-left">
                      {{ item.Descrizione}}
                    </div>
                    <div class="col text-left">
                        {{ item.CodArt }}
                    </div>
                    <div class="col text-left">
                        {{ item.number }}
                    </div>
                    <div class="col">
                        <ul class="list-inline text-left">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                              <a href="#editmodalProd" data-bs-toggle="modal"
                                class="text-primary d-inline-block edit-item-btn" @click="editdata(item)">
                                <i class="ri-pencil-fill fs-16"></i>
                              </a>
                            </li>
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                            </li>
                        </ul>
                    </div>
                </div>
          </div>

            <div>
              <div class="table-responsive table-card mb-1">

                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3" v-if="n_results > perPage">        
                    <div class="col-lg-3 col-md-12 ">
                        <label class="pt-2">Risultati per pagina:</label>
                        <div class="pl-10 pr-20">
                        
                        <select class="form-control" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                        <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
        </div>
    </div>
    <div class="modal fade" id="staticBackdropEquip" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropEquipLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">                 
            <div class="modal-header  bg-light p-3">
                <h5 class="modal-title" id="varyingcontentModalLabel">Inserisci anagrafica prodotto</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form class="needs-validation" @submit.prevent="initItem">
            <div class="modal-body">
                <div class="mb-3">                
                  <label for="name" class="form-label">Denominazione prodotto <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="item.name" id="name" :class="{
                      'is-invalid': submitted && v$.item.name.$error,
                    }" placeholder="Inserisci denominazione prodotto">
                        <div v-for="(item, index) in v$.item.name.$errors" :key="index" class="invalid-feedback">
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                </div>
                <div class="mb-3">                
                  <label for="name" class="form-label">Codice di serie prodotto <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="item.code" id="name" :class="{
                      'is-invalid': submitted && v$.item.code.$error,
                    }" placeholder="Inserisci denominazione prodotto">
                        <div v-for="(item, index) in v$.item.code.$errors" :key="index" class="invalid-feedback">
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                </div>
                <div class="mb-3">                
                  <label for="manufacturer" class="form-label">Marca prodotto<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="item.manufacturer" id="manufacturer" :class="{
                      'is-invalid': submitted && v$.item.manufacturer.$error,
                    }" placeholder="Inserisci marca prodotto">
                        <div v-for="(item, index) in v$.item.manufacturer.$errors" :key="index" class="invalid-feedback">
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                </div>
                
                <div class="mb-3">
                  <label for="details" class="form-label">Descrizione (facoltativa)</label>
                  <textarea class="form-control " id="details" placeholder="Descrizione" v-model="item.details"></textarea>
                  <div class="invalid-feedback">
                 
                  </div>
                </div>

                  <div class="mb-3">
                    <select class="form-select" required aria-label="Proprietà del prodotto" v-model="item.ownershipDesc" :class="{
                      'is-invalid': submitted && v$.item.ownershipDesc.$error,
                    }">
                      <option value="">Seleziona Proprietà</option>
                      <option value="aziendale">Aziendale</option>
                      <option value="locazione">In locazione</option>
                      <option value="noleggio">A noleggio</option>
                    </select>
                      <div v-for="(item, index) in v$.item.ownershipDesc.$errors" :key="index" class="invalid-feedback">
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                  </div>
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button type="button" id="closemodalEquip" class="btn btn-light" data-bs-dismiss="modal">
                  Chiudi
                </button>
                <button type="submit" class="btn btn-info" >
                  <i class="ri-mail-send-fill me-2 align-bottom"></i>
                    Inserisci anagrafica
                </button>
              </div>
            </div>
          </form>
        </div>
    </div>
</div>

<div class="modal fade" id="editmodalProd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropEquipLabel" aria-hidden="true">
              <div class="modal-dialog  modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3 pl-5 pr-5">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Aggiorna prodotto in lista
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal" >
                    </button>
                  </div>
                  <form class="needs-validation" @submit.prevent="updatedata">
                    <div class="modal-body">
                      <div class="mb-3">                
                        <label for="Descrizione" class="form-label">Denominazione prodotto </label>
                        <div>
                            {{ dataEdit.Descrizione }}
                        </div>
                      </div>
                      <div class="mb-3">                
                        <label for="CodArt" class="form-label">Codice prodotto </label>
                        <div>
                            {{ dataEdit.CodArt }}
                        </div>
                      </div>
                      
                      <div class="mb-3">
                        <label for="details" class="form-label">Descrizione sulle modalità di utilizzo (facoltativa)</label>
                        <textarea class="form-control " id="details" placeholder="Descrizione" v-model="dataEdit.details"></textarea>
                        <div class="invalid-feedback">
                      
                        </div>
                      </div>

                        <div class="mb-3">
                            <label for="number" class="form-label">Proprietà del prodotto<span class="text-danger">*</span></label>
                            <div>
                                <select class="form-select" required aria-label="Proprietà del prodotto" v-model="dataEdit.ownershipDesc" :class="{
                                    'is-invalid': submitted && v$.item.ownershipDesc.$error,
                                }">
                                    <option value="">Seleziona Proprietà</option>
                                    <option value="aziendale">Aziendale</option>
                                    <option value="locazione">In locazione</option>
                                    <option value="noleggio">A noleggio</option>
                                </select>
                                    <div v-for="(item, index) in v$.dataEdit.ownershipDesc.$errors" :key="index" class="invalid-feedback">
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                            </div>
                        </div>
                        <div class="mb-3">
                          <div class="row">
                            <div class="col">
                              <label for="number" class="form-label">Numero di pezzi utilizzati<span class="text-danger">*</span></label>
                          <input type="number" class="form-control" v-model="dataEdit.number" id="number" :class="{
                            'is-invalid': submitted && v$.dataEdit.number.$error,
                          }" placeholder="Inserisci numero di pezzi utilizzati">
                              <div v-for="(item, index) in v$.dataEdit.number.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                            </div>
                            <div class="col">
                                <label for="cost" class="form-label">Costo</label>
                              <input type="number" class="form-control" v-model="dataEdit.cost" placeholder="Inserisci Costo">
                            </div>
                          </div>           
                        </div>
                  </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" id="editclosemodalEquip" class="btn btn-light" data-bs-dismiss="modal">
                          Chiudi
                        </button>
                        <button type="submit" class="btn btn-success">
                          Aggiorna
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>